import { Button, CircularProgress, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { actions } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { DEVICES } from "../../utils/patient.constants";
const ConnectDevice = ({
  onEnableChange = (val) => {},
  onLoadingChange = (loader, messages) => {},
  onConnectWithDevice = (hardwareData, deviceInformation, disconnectFun) => {},
  onDisconnect = () => {},
  onWriteFunctionEnabled = (fun) => {},
  // onEqCharacteristicChange = ()=>{},
  Component,
}) => {
  const {fitting}=useSelector(state=>state)
  //this is to indetify device will work on this device or not
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();
  //this is to notify device is connected or not
  const [connected, setConnected] = useState();

  //this will mainatain all loading information & states
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Checking Browser Support"
  );

  const [servers, setServer] = useState(null);
  const [services, setServices] = useState(null);
  const [charateristic, setCharacteristics] = useState(null);

  //this will contain whole device object with all its functionality that which device is conncetd
  const [deviceObj, setDeviceObj] = useState(null);

  const [deviceInfo, setDeviceInfo] = useState({ name: "", id: "" });

  const [device, setDevices] = useState(null);

  const [writeFunction, setWriteFunction] = useState(undefined);

  //data read by device
  const [data, setData] = useState([]);

  // let eq_characteristic

  const readDeviceNameValue = async (characteristic) => {
    const value = await characteristic.readValue();

    setDevices(new TextDecoder().decode(value));
    // console.log('> Device Name: ' + );
  };

  const readAndDecodeData = async (characteristic) => {
    const value = await characteristic.readValue();

    let len = value.byteLength;
    var arr_res = new Array(len);
    // console.log(' > Received BLE Data,size=' + len);
    for (var i = 0; i < len; i++) {
      arr_res[i] = value.getUint8(i, true);
    }

    return arr_res;
  };

  const readAppearanceValue = async (characteristic) => {
    const arr_res = await readAndDecodeData(characteristic);
    console.log("on Connect", arr_res);

    setData(arr_res);
    dispatch({ type: actions.CHECKED_VAL, arr_res });
    setWriteFunction({
      readData: async () => {
        return await readAndDecodeData(characteristic);
      },
      writeData: async (arr_to_be_write) => {
        return await characteristic.writeValueWithoutResponse(
          Uint8Array.from(arr_to_be_write)
        );
      },
    });

    return arr_res;
  };
  const connectDevice = async () => {
    try {
      setLoadingMessage("Connecting Device...");
      setLoading(true);

      const serviceUUid = "e093f3b5-00a3-a9e5-9eca-40016e0edc24";
      const characteristicUuid = "e093f3b5-00a3-a9e5-9eca-40036e0edc24";

      const device = await navigator.bluetooth
        .requestDevice({
          acceptAllDevices: true,
          optionalServices: [serviceUUid],
        })
        .catch((e) => {
          setLoadingMessage(
            "Failed To coonect because of " + e.message ?? "errors"
          );
          setLoading(false);
        });
      if (!device) {
        setLoadingMessage("Can not connect device.");
        setLoading(false);
        return;
      
      }

      // if(device.name.charAt(0)==='H' && fitting.device_type!=DEVICES.BTE){
      //  setLoadingMessage("Can not connect device because device does not match.");
      //   setLoading(false);
      //   return;
      // }

     

      // if(device.name.charAt(0)==='B' && fitting.device_type!=DEVICES.BTE_32){
      //   setLoadingMessage("Can not connect device because device does not match.");
      //   setLoading(false);
      //   return;
      // }

      // if(device.name.charAt(device.name.length-1)=='L' && fitting.device_side!=1){
      //   setLoadingMessage("Can not connect device because device side does not match.");
      //   setLoading(false);
      //   return;
      // }

      // if(device.name.charAt(device.name.length-1)=='R' && fitting.device_side!=2){
      //   setLoadingMessage("Can not connect device because device side does not match.");
      //   setLoading(false);
      //   return;
      // }

     
      console.log("DeviceName", device.name);
      setDeviceInfo({
        name: device.name,
        id: device.id,
      });

      device.ongattserverdisconnected = disconnect;
      // device.addEventListener('gattserverdisconnected', disconnect);

      setLoadingMessage("connecting Device Server....");
      setDeviceObj(device);
      const server = await device.gatt.connect();

      setServer(server);
      setLoadingMessage("connecting Services...");

      const service = await server.getPrimaryService(serviceUUid).catch(() => {
        setLoadingMessage("error connecting Services.");
      });

      setServices(service);
      setLoadingMessage("Fetching Characteristics...");

      const characteristics = await service.getCharacteristics();

      setCharacteristics(characteristics);
      setLoadingMessage("Connected.");
      setConnected(true);

      let readData;
      let i = 0;
      for (const characteristic of characteristics) {
        i++;
        switch (characteristic.uuid) {
          case window.BluetoothUUID.getCharacteristic("gap.appearance"):
            await readAppearanceValue(characteristic);
            break;

          case window.BluetoothUUID.getCharacteristic("gap.device_name"):
            await readDeviceNameValue(characteristic);
            break;
          case "e093f3b5-00a3-a9e5-9eca-40036e0edc24":
            // eq_characteristic = characteristic;
            readData = await readAppearanceValue(characteristic);
            break;

          default:
            //log('> Unknown Characteristic: ' + characteristic.uuid);
            //await readDeviceNameValue(characteristic);

            break;
        }
      }

      setLoading(false);
    } catch (e) {
      setLoadingMessage(e.message ?? "OOPsss!!");
      setLoading(false);
    }
  };

  const disconnect = (btn) => {
    console.log("disconnected");
    // if (servers && deviceObj) {
    if (btn === true) {
      if (deviceObj?.gatt?.connected) {
        console.log("hard disconnect");
        deviceObj.gatt?.disconnect?.();
      } else {
        setLoadingMessage("already Disconnected..");
      }
    }
    onDisconnect();
    setDeviceInfo({});
    setConnected(false);
    setDeviceObj(null);
    setServer(null);
    setDevices(null);
    setCharacteristics(null);
    setServices(null);
    setData(null);
    setLoadingMessage("");
    // }
  };

  useEffect(() => {
    if (window.navigator && window.navigator.bluetooth) {
      setLoading(false);
      setLoadingMessage("");
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    // if (!loading)
    onEnableChange(enabled);
  }, [enabled]);

  useEffect(() => {
    onLoadingChange(loading, loadingMessage);
  }, [loading, loadingMessage]);

  useEffect(() => {
    onWriteFunctionEnabled(writeFunction);
    console.log("Function", writeFunction);
  }, [writeFunction]);

  useEffect(() => {
    if (deviceObj && data?.length > 0 && connected && deviceInfo.id != "")
      onConnectWithDevice(data, deviceInfo, () => disconnect(true));
  }, [deviceObj, data, deviceInfo, connected]);

  // console.log("servers", servers)
  // console.log("services", services)
  // console.log("charateristic", charateristic)
  // console.log("deviceObj", deviceObj)
  // console.log("device", device)
  // console.log("data", data)

  if (Component) {
    return (
      <Component
        loading={loading}
        connected={connected}
        onClick={connectDevice}
        disconnect={() => disconnect(true)}
      />
    );
  }

  if (!enabled) {
    return (
      <Typography color="error">
        This is browser is not allowed to acess bluettoth Low Energy{" "}
      </Typography>
    );
  }

  return (
    <>
      {loading && <CircularProgress />}
      {loadingMessage && <Typography>{loadingMessage}</Typography>}
      {!connected && (
        <Button variant="contained" onClick={connectDevice}>
          Connect Device
        </Button>
      )}
      {connected && (
        <>
          Connceted With: {deviceInfo.name + " " + deviceInfo.id}
          <Button onClick={() => disconnect(true)}>Disconnect</Button>
        </>
      )}
    </>
  );
};
export default memo(ConnectDevice);
